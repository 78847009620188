import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import React from "react";
import {selectCartComments, setCartNotes} from "../cartSlice";
import {Translate} from "../../intl/translator";

export function CartComments() {
    const strings = {
        s_placeholder: Translate('cart_screen_comments')
    }
    const dispatch = useAppDispatch();
    const notes = useAppSelector(selectCartComments);

    const setNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setCartNotes(e.target.value));
    }
    return (
        <textarea
            onChange={setNotes}
            autoFocus={false}
            className="
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                     bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    mt-10
                    card-modded-bg
                    focus:text-gray-700 focus:bg-white focus:border-black focus:outline-none
                  "

            rows={4}
            placeholder={strings.s_placeholder}
            defaultValue={notes}
        >
            </textarea>
    )
}
