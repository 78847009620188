import axios from "axios";
import CONFIG from "../../app/config";
import {Order} from "./Order";
import {NinaOrderI} from "./cartInterfaces";
import { info, table } from "console";
import { config } from "process";

export interface CreateOrderResponseI{
    OrderID: string,
    Status: string
}

export interface CreatePaymentRequestOrderResponseI{
    PaymentRequestID: string,
    Status: string
}
export function createOrder(order: Order, locale: string) {
    return new Promise<CreateOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/cart/order`, {
            order: order.formattedOrder(),
            locale
        })
            .then(res => {
                const response: CreateOrderResponseI = res.data[0];
                resolve(response);
            })
            .catch(e => reject(e))
    })
}
export function createRequest(order: Order, locale: string, total: string) {
    return new Promise<CreateOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/cart/order/request`, {
            order: order.formattedOrder(),
            total: total,
            Creatfrom: "webapp",
            locale
        })
            .then(res => {
               
                const response: CreateOrderResponseI = res.data;
                // console.log("test here: "+response);
                resolve(response);
            })
            .catch(e => reject(e))
    })
}

// export const navigateToTips = async (tableid: string, outletID: string, userID: string, amount: string, generalNotes: string, orderType: string, order: Order) => {
//     const cOrder = order.formattedOrder();
//     const payload = {
//         TableID: tableid,
//         OutletID: outletID,
//         RegisteredUserID: userID,
//         AmountTotal: amount,
//         CustomerGeneralNote: generalNotes,
//         OrderType: orderType,
//         CreateFrom: "1",
//         ScanTimeStamp: Date.now(),
//         Orderitems: cOrder.Orderitems
//     };

//     // Store payload in sessionStorage
//     sessionStorage.setItem('payload', JSON.stringify(payload));

//     // Redirect to URL
//     window.location.href = CONFIG.TIPS_URL;
// };

export const navigateToTips = async (request: String) => {
    
    const payload = {
        RequestID: request
        
    };
    // console.log("payload from web: "+ request);
    // console.log("payload from web: "+JSON.stringify(payload));


    // Set the cookie with domain attribute
    document.cookie = `requestID=${request}; domain=.devnina.com; path=/`;
    document.cookie = `payload=${encodeURIComponent(JSON.stringify(payload))}; domain=.devnina.com; path=/`;

    // Redirect to the tips URL
    window.location.href = `${CONFIG.TIPS_URL}`;
};
// export const navigateToTips = async (tableid: string, outletID: string, userID: string, amount: string, generalNotes: string, orderType: string, order: Order) => {
//     const cOrder = order.formattedOrder();
//     const url = `${CONFIG.TIPS_URL}`;
    
//     // Serialize payload into query parameters (ensure it's URL-safe)
//     const queryParams = new URLSearchParams({
//         TableID: tableid,
//         OutletID: outletID,
//         RegisteredUserID: userID,
//         AmountTotal: amount,
//         CustomerGeneralNote: generalNotes,
//         OrderType: orderType,
//         CreateFrom: "1",
//         ScanTimeStamp: Date.now().toString(), // Convert to string
//         Orderitems: JSON.stringify(cOrder.Orderitems) // Serialize array as JSON string
//     }).toString();

//     // Redirect with query parameters
//     window.location.href = `${url}?${queryParams}`;
// };
// export const navigateToTips = async ( tableid: string, outletID: string, userID: string, amount: string, generalNotes: string, orderType: string , order: Order) => {
//     const cOrder =  order.formattedOrder();
//     const url = `${CONFIG.TIPS_URL}`; // Replace with the actual endpoint
//     const bodyData = [
//       {
//         "TableID": tableid,
//         "OutletID": outletID,
//         "RegisteredUserID": userID,
//         "AmountTotal": amount,
//         "CustomerGeneralNote": generalNotes,
//         "OrderType": orderType,
//         "CreateFrom": "1",
//         "ScanTimeStamp": Date.now(),
//         "Orderitems": cOrder.Orderitems
//       }
//     ];
//     try {
//       const response = await axios.post(url, bodyData, {
//         headers: {
//           'Content-Type': 'application/json', // Only include content type header
//         }
//       });
  
//       console.log('Response:', response.data);
//       window.location.href = url;


//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };
export function createPaymentRequestOrder(order: Order, locale: string) {
    return new Promise<CreatePaymentRequestOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/payments/request`, {
            order: order.formattedOrder(),
            locale
        })
            .then(res => {
                const response: CreatePaymentRequestOrderResponseI = res.data;
                resolve(response);
            })
            .catch(e => console.log(e))
    })
}

export function lastOrder(userId: string, outletId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order/last?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}

export function loadOrder(orderId: string, localeId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order?orderId=${orderId}&localeId=${localeId}`)
            .then(res => {
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}

export function jccRequest(orderId: string, orderTotal: string, jccAccountId: string){
    return new Promise<string>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/payments/jcc/request`, {
            orderId,
            orderTotal,
            jccAccountId,
        })
            .then(res => {
                const form: string = res.data;
                resolve(form);
            })
            .catch(e => console.log(e))
    })
}
