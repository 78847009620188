// take a price from api e.g "6.5" and convert it readable currency format
export const formatCurrency = (price?: number | string, symbol?: string) => {
    if(!symbol) {
        const data = localStorage.getItem('NINA-APP');
        const state = JSON.parse(data ?? '')
        symbol = state?.vendor?.data?.CurrencySymbol ?? '€';
    }
    return symbol + Number(price).toFixed(2);
}
export const formatNoCurrency = (price?: number | string, symbol?: string) => {
    if(!symbol) {
        const data = localStorage.getItem('NINA-APP');
        const state = JSON.parse(data ?? '')
        symbol = state?.vendor?.data?.CurrencySymbol ?? '€';
    }
    return Number(price).toFixed(2);
}

