import {CartInterface, cartItemVariationGroupInterface, OrderI, OrderItemI, OrderItemVariationI} from "./cartInterfaces";
import {CartItem} from "./CartItem";


export class Order{
    cart: CartInterface;
    info: OrderI;

    constructor(cart: CartInterface) {
        this.cart = cart;
        this.info = {
            Reguserid: "0",
            Ptypeid: 5,
            Customergeneralnotes: cart.notes ?? "",
            OrderType: 0,
            Scantimestamp: "",
            Tableid: 0,
            Outletid: 0,
            Roomnum: "0",
            Houseaccount: "0",
            Orderitems: []
        }
    }

    formattedOrder(): OrderI {
        this.generateOrderItems();
        return this.info;
    }
   

    generateOrderItems() {
        this.cart.items.forEach((cartItem, index) => {
            let item = new CartItem(cartItem);
            let orderItem: OrderItemI = {
                AllDayClosed: "0",
                Calories: "0",
                CategoryName: item.Product.CategoryName ?? "None",
                CustomID: index.toString(),
                DefaultPreparationTime: item.Product.DefaultPreparationTime ?? "0",
                Description: item.Product.Description,
                DisplayCalories: item.Product.DisplayCalories ?? "0",
                DisplayMenu: item.Product.DisplayMenu ?? "0",
                FileName: item.Product.FileName ?? "",
                ItemName: item.Product.ItemName,
                ItemVariationID: "0",
                MenuItemID: item.Product.MenuItemID,
                Notes: item.Notes ?? "",
                Price: item.Product.Price,
                PrinterID: item.Product.PrinterID ?? "0",
                Quantity: item.Qty?.toString() ?? "1",
                Recommended: item.Product.Recommended ?? "0",
                PromoID: item.Product.PromoID ?? "0",
                TAG: "MenuItem",
                TagItem: item.Product.TagItem ?? "-1",
                TagName: item.Product.CategoryName ?? "None",
                TotalItemPriceWithVariation: item.displayFormattedPrice(),
                itemNum: 1,
                itemOrderList: 0,
                nameWithVariation: this.generateOrderItemVariations(item),
                selectedVariations: []
            }
            this.info.Orderitems.push(orderItem);
           
            
        })
    }

    generateOrderItemsTips() {
        this.cart.items.forEach((cartItem, index) => {
            let item = new CartItem(cartItem);
            let orderItem: OrderItemI = {
                AllDayClosed: "0",
                Calories: "0",
                CategoryName: item.Product.CategoryName ?? "None",
                CustomID: index.toString(),
                DefaultPreparationTime: item.Product.DefaultPreparationTime ?? "0",
                Description: item.Product.Description,
                DisplayCalories: item.Product.DisplayCalories ?? "0",
                DisplayMenu: item.Product.DisplayMenu ?? "0",
                FileName: item.Product.FileName ?? "",
                ItemName: item.Product.ItemName,
                ItemVariationID: "0",
                MenuItemID: item.Product.MenuItemID,
                Notes: item.Notes ?? "",
                Price: item.Product.Price,
                PrinterID: item.Product.PrinterID ?? "0",
                Quantity: item.Qty?.toString() ?? "1",
                Recommended: item.Product.Recommended ?? "0",
                PromoID: item.Product.PromoID ?? "0",
                TAG: "MenuItem",
                TagItem: item.Product.TagItem ?? "-1",
                TagName: item.Product.CategoryName ?? "None",
                TotalItemPriceWithVariation: (item.calcPrice() /100) + "",
                itemNum: 1,
                itemOrderList: 0,
                nameWithVariation: this.generateOrderItemVariations(item),
                selectedVariations: []
            }
            this.info.Orderitems.push(orderItem);
           
            
        })
    }

    generateOrderItemVariations(item: CartItem): Array<OrderItemVariationI> {
        let variantGroups: Array<cartItemVariationGroupInterface> = item.Variations;
        let variations: Array<OrderItemVariationI> = [];
        variantGroups.forEach(variant => {
            variant.items.forEach((option, index) => {
                let orderItemVariation: OrderItemVariationI = {
                    CustomID: index.toString(),
                    OptionID: option.OptionID,
                    Quantity: "1",
                    SelectionName: option.Name,
                    ShowQuantity: option.ShowQuantity,
                    TAG: "OrderItemVariationOptions",
                    VariationID: variant.group.ItemVariationID,
                    hasDefaultPrice: option.hasDefaultPrice,
                    optionName: option.Name,
                    price: option.price,
                    variationName: variant.group.Name,
                    QuantityLimit: option.QuantityLimit,
                    MenuItemID: item.Product.MenuItemID
                }
                let existingIndex = variations.findIndex(v => {
                    return v.OptionID === orderItemVariation.OptionID
                })
                if( existingIndex >= 0){
                    let existingQty = parseInt(variations[existingIndex].Quantity);
                    variations[existingIndex].Quantity = (existingQty + 1).toString()
                }else{
                    variations.push(orderItemVariation);
                }
            })
        })
        return variations;
    }

}
